'use client';

import {
    darkTheme,
    getDefaultConfig,
    RainbowKitProvider
} from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { coinbaseWallet, injectedWallet, metaMaskWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { base } from 'viem/chains';
import { http, WagmiProvider } from 'wagmi';

coinbaseWallet.preference = 'smartWalletOnly';

const queryClient = new QueryClient();

const config = getDefaultConfig({
    appName: 'Metacade Tournaments',
    projectId: '1f9ac310962d08ec19ea4dc906b495ac',
    chains: [base],
    appDescription: 'Metacade Tournaments',
    appIcon: 'https://api.metafuse.me/assets/metacade/metacade.webp',
    appUrl: 'https://tournaments.metacade.co',
    multiInjectedProviderDiscovery: false,
    transports: {
        [base.id]: http(process.env.NEXT_PUBLIC_BASE_RPC_ENDPOINT),
    },
    wallets: [
        {
            groupName: 'Recommended',
            wallets: [
                coinbaseWallet,
            ],
        },
        {
            groupName: 'Others',
            wallets: [
                metaMaskWallet,
                walletConnectWallet,
                injectedWallet,
            ],
        }
    ],
})

const WalletProvider = ({ children }: any) => {
    return (
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider theme={darkTheme()}>
                    {children}
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider >
    );
};

export default WalletProvider;